// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/entry.client.tsx"
);
import.meta.hot.lastModified = "1734124776000";
}
// REMIX HMR END

import { hydrateRoot } from 'react-dom/client';
import { RemixBrowser } from '@remix-run/react';

/**
 * Globally expose our API URL to the client-side code.
 */
declare global {
  interface Window {
    ENV: {
      BIOSONAH_API_URL: string;
      VENDURE_ASSET_URL: string;
    };
  }
}

hydrateRoot(document, <RemixBrowser />);
